<template>
  <el-dialog
    top="50px"
    :visible="visible"
    :title="data ? 'Sửa lịch trình' :'Thêm lịch trình'"
    width="700px"
    close-on-press-escape
    close-on-click-modal
    @close="handleClose"
  >
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item v-if="!data" label="Loại tour" prop="type">
        <el-select
          v-model="form.type"
          filterable
          class="w-100"
          @change="handleChangePlaceType"
        >
          <el-option v-for="t in placeTypes" :key="t.value" :label="t.name" :value="t.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="!data" label="Điểm đến" prop="place_id">
        <el-select
          v-model="form.place_id"
          class="w-100"
          filterable
          remote
          placeholder="Chọn điểm đến"
          :remote-method="searchPlace"
          :loading="searching"
        >
          <el-option v-for="p in places" :key="p.id" :label="p.name" :value="p.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Thời gian (Số giờ)" prop="time">
        <el-input v-model.number="form.time" type="number" placeholder="Nhập vào thời gian"></el-input>
      </el-form-item>
      <el-form-item label="Quãng đường (Km)" prop="distance">
        <el-input v-model.number="form.distance" type="number" placeholder="Nhập vào quãng đường"></el-input>
      </el-form-item>
      <el-form-item prop="description">
        <p>Mô tả</p>
        <editor :value="form.description" @change="(v) => form.description = v" />
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button :disabled="callingAPI" @click="handleClose">Hủy</el-button>
      <el-button type="primary" :loading="callingAPI" @click="handleSaveInfo">Lưu</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { createSchedule, updateSchedule } from '@/services/tour'
import Editor from '../items/Editor.vue'
import { getPlaceTypes } from '@/utils/filters'
import { listPlaces } from '@/services/place'

export default {
  name: 'Schedule',
  components: {
    Editor
  },
  props: {
    visible: Boolean,
    data: Object
  },
  data() {
    return {
      callingAPI: false,
      places: [],
      searchText: '',
      form: {
        type: '',
        place_id: this.$route.params.id || '',
        time: this.data ? this.data.time : 0,
        distance: this.data ? this.data.distance : 0,
        description: this.data ? this.data.description : ''
      },
      searching: false,
      rules: {
        type: [
          { required: true, message: 'Loại tour không được để trống', trigger: ['blur', 'change'] }
        ],
        place_id: [
          { required: true, message: 'Tour không được để trống', trigger: ['blur', 'change'] }
        ],
        time: [
          { required: true, message: 'Giá trị không được để trống', trigger: ['blur', 'change'] },
          { type: 'number', message: 'Giá trị phải là số', trigger: ['blur', 'change'] }
        ],
        distance: [
          { required: true, message: 'Giá trị không được để trống', trigger: ['blur', 'change'] },
          { type: 'number', message: 'Giá trị phải là số', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['language']),
    placeTypes() {
      return getPlaceTypes(false).filter((p) => p.type === 'place' && ['tour', 'commerce'].includes(p.value))
    },
    params() {
      return {
        locale: this.language,
        place_types: this.form.type,
        per_page: 10,
        search_text: this.searchText
      }
    }
  },
  beforeMount() {
    this.form.type = this.placeTypes[0].value
    this.getPlaces()
  },
  methods: {
    handleSaveInfo() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.data) {
            this.updateSchedule()
          } else {
            this.createSchedule()
          }
        }
      })
    },
    createSchedule() {
      this.callingAPI = true
      const data = {
        time: Number(this.form.time),
        distance: Number(this.form.distance),
        description: this.form.description,
        locale: this.language,
        place_id: this.form.place_id || this.$route.params.id
      }
      createSchedule(data).then(() => {
        this.$notify.success({
          title: 'Thông báo',
          message: 'Tạo lịch trình thành công'
        })
        this.handleClose()
        this.$emit('reload')
        this.callingAPI = false
      }).catch(() => {
        this.$notify.error({
          title: 'Thông báo',
          message: 'Tạo lịch trình thất bại'
        })
        this.callingAPI = false
      })
    },

    updateSchedule() {
      this.callingAPI = true
      updateSchedule({ ...this.data, ...this.form }).then(() => {
        this.$notify.success({
          title: 'Thông báo',
          message: 'Cập nhật thành công'
        })
        this.handleClose()
        this.$emit('reload')
        this.callingAPI = false
      }).catch(() => {
        this.$notify.error({
          title: 'Thông báo',
          message: 'Cập nhật trình thất bại'
        })
        this.callingAPI = false
      })
    },
    getPlaces() {
      this.searching = true
      const request = listPlaces(this.params)
      request.then((res) => {
        this.places = res.result
        this.searching = false
      }).catch(() => {
        this.places = []
        this.searching = false
      })
    },
    handleChangePlaceType() {
      this.form.place_id = ''
      this.searchText = ''
      this.getPlaces()
    },
    searchPlace(text) {
      this.searchText = text
      setTimeout(() => {
        this.getPlaces()
      }, 100)
    },
    handleClose() {
      this.$refs.form.resetFields()
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.el-dialog__wrapper {
  z-index: 1002 !important;
}
</style>
